import BookingService from '../bookings.service'
import MemberService from '../members.service'
import EmployeeService from '../employees.service'
import ProgramService from '../../program/program.service'
import ReportsService from '../../reports/reports.service'
import DashboardService from '../../dashboard/dashboard.service'
import { PurchaseValidationStatus } from '../purchase-validation-status.enum'
import { PurchaseStatusEnum } from '../member-details/memberships-tab/membership-detail/purchase-status.enum'

const bookingService = BookingService()
const memberService = MemberService()
const employeeService = EmployeeService()
const programService = ProgramService()
const reportsService = ReportsService()
const dashboardService = DashboardService()

export const getMemberships = async (store, memberId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app?.actions

  incrementLoading(store)
  try {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        membershipList: [],
        currentTab: 'memberships',
        isCustomerDataMissing: false,
      },
    }))
    const items = await memberService.getMemberships(memberId)
    const programId = state.Reservations.matchingUsers && state.Reservations.matchingUsers.length > 0 ? state.Reservations.matchingUsers[0].programId : 0
    const isCustomerDataMissing = await memberService.getCustomerDataMissing(memberId, programId)

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        membershipList: items,
        currentTab: 'memberships',
        isCustomerDataMissing: isCustomerDataMissing
      },
    }))
    decrementLoading(store)
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}
export const getCurrentMembershipInfo = async (store, { membershipId, programId, upgradeInfo }) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      isLoadingCurrentMembership: true,
      isCustomerDataMissing: false,
    },
  }))

  try {
    const membershipInfo = await memberService.getMembershipsById(
      programId,
      membershipId
    )

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        currentMembership: membershipInfo,
        isLoadingCurrentMembership: false,
      },
    }))
    return membershipInfo;
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      MembershipSubscription: {
        ...prevState.MemberDetails,
        isLoadingCurrentMembership: false,
      },
    }))
  }
}

export const setCurrentTab = (store, tabName) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, currentTab: tabName },
  }))
}

export const setMembershipToEdit = async (
  store,
  { memberId, subscriptionId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    clearMembershipToEdit(store)
    const membershipToEdit = await memberService.getMembershipDetail(
      memberId,
      subscriptionId,
    )
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, membershipToEdit },
    }))
    decrementLoading(store)
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const setMemberToEdit = async (store, memberId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    clearMembershipToEdit(store)
    const memberToEdit = await memberService.getMemberById(memberId)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, memberToEdit },
    }))
    decrementLoading(store)
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const clearMemberToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      membershipList: [],
      membershipToEdit: undefined,
      memberToEdit: undefined,
      suspendedMembershipInfo: undefined,
      currentTab: 'memberships',
      savedCreditCards: [],
      isLoadingSavedCreditCard: false,
      creditCardDeleteError: undefined,
      isLoadingReceipts: false,
      receipts: { items: [], count: 0 },
      bookingToEdit: undefined,
      bookings: {
        activeBookings: [],
        pastBookings: [],
        activeBookingsTotalItems: 0,
        pastBookingsTotalItems: 0
      },
      userInformation: undefined,
      employees: [],
    },
  }))
}

export const clearMembershipTab = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      membershipList: [],
      currentMembership: undefined,
      isLoadingCurrentMembership: false,
      membershipToEdit: undefined,
      suspendedMembershipInfo: undefined,
      savedCreditCards: [],
      isLoadingSavedCreditCard: false,
      creditCardDeleteError: undefined,
      isLoadingReceipts: false,
      receipts: { items: [], count: 0 },
      paymentError: undefined,
      purchaseConfirmationData: undefined,
      isAddCardVisible: false,
      isLoadingSavedCreditCard: false,
      isDeletingCreditCard: false,
      selectedEmployeeId: undefined,
      isLoadingEmployees: false,
      isUserAlreadyHaveMembershipDetails: false,
      upgradeMembership: {
        invoice: undefined,
        isLoadingInvoice: false,
        targetMembership: undefined,
        isLoadingTargetMembership: false,
        targetBillingPlanId: undefined
      },
      showCheckoutInformation: false,
      purchaseValidationStatus: PurchaseValidationStatus.DEFAULT,
      // purchaseMembershipfilters:{
      //   filters:{
      //     programId:0,
      //   billingPlanId:0,
      //   membershipId:0
      //   },
      //   memberId:''
      // },
      availableMemberships: undefined,
      upgradeInfo: undefined,
      termsAndConditions: undefined,
      isLoadingTermsAndConditions: false,
      purchaseMembership: { billingPlanSummary: undefined, isLoadingInvoice: false, targetBillingPlanId: undefined, info: { id: undefined }, scheduleId: undefined },
    },
  }))
}
export const clearUpgradeMembershipState = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      upgradeMembership: { ...prevState.MemberDetails.upgradeMembership, isLoadingInvoice: false },
      availableMemberships: undefined,
      showCheckoutInformation: false
    },
  }))
}
export const clearAvailableMemberships = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      availableMemberships: undefined,
      showCheckoutInformation: false
    },
  }))
}
export const clearSourceMSubscriptionState = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      upgradeInfo: undefined,
      purchaseMembership: { billingPlanSummary: undefined, isLoadingInvoice: false, targetBillingPlanId: undefined, info: { id: undefined } },
      availableMemberships: undefined,
      showCheckoutInformation: false
    },
  }))
}
export const clearPurchaseMembershipState = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      purchaseMembership: { ...prevState.MemberDetails.purchaseMembership, isLoadingInvoice: false },
      availableMemberships: undefined,
      upgradeInfo: undefined,
      showCheckoutInformation: false
    },
  }))
}

export const getUserInformation = async (store, { memberId, clickedAddMember, programId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const userInformation = await memberService.getUserInformation(memberId, clickedAddMember, programId)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        userInformation,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}
// export const createUser = async (
//   store,
//   { newUserInformation, programId },
// ) => {
//   const state = store.state
//   const { incrementLoading, decrementLoading } = state.app.actions
//   const showNotification = store.state.Notification.actions.showNotification

//   incrementLoading(store)
//   try {
//     const userInformation = await memberService.createUserInformation(newUserInformation, programId)
//    // console.log(userInformation)
//    // await setMemberToEdit(store, userInformation.Id)
//     await showNotification({
//       type: 'success',
//       message: 'User successfully created. Redirecting to payment check out page.',
//     })
//     store.setState(prevState => ({
//       ...prevState,
//       MemberDetails: {
//         ...prevState.MemberDetails,
//         userInformation: userInformation,
//       },
//       Member: {
//         ...prevState.Member,
//         addMemberInfo: userInformation,
//       },
//     }))
//     return userInformation
//   } catch (error) {
//     let msg
//     if (error.response?.data !== undefined && error.response?.data === 'Email is already in use.') {
//       msg = error.response?.data
//     } else {
//       msg = 'An error occurred while trying to create user'
//     }
//     await showNotification({
//       type: 'error',
//       message: msg,
//     })
//   } finally {
//     decrementLoading(store)
//   }
// }
export const createUser = async (
  store,
  { newUserInformation, programId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    const userInformation = await memberService.createUserInformation(newUserInformation, programId)
    // console.log(userInformation)
    // await setMemberToEdit(store, userInformation.Id)
    await showNotification({
      type: 'success',
      message: 'User successfully created. Redirecting to payment check out page.',
    })
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        userInformation: userInformation,
      },
      Member: {
        ...prevState.Member,
        addMemberInfo: userInformation,

      },
    }))
    return userInformation
  } catch (error) {
    let msg
    if (error.response?.data !== undefined && error.response?.data === 'Email is already in use') {
      msg = error.response?.data
    } else {
      msg = 'An error occurred while trying to create user'
    }
    await showNotification({
      type: 'error',
      message: msg,
    })
  } finally {
    decrementLoading(store)
  }
}

export const UpdateUser = async (
  store,
  { memberId, newUserInformation, clickedAddMember, programId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await memberService.updateUserInformation(memberId, newUserInformation, clickedAddMember, programId)
    await showNotification({
      type: 'success',
      message: 'User successfully updated. Redirecting to payment check out page.',
    })
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        userInformation: newUserInformation,
      },
      Member: {
        ...prevState.Member,
        addMemberInfo: newUserInformation,

      },
    }))
    return newUserInformation
  } catch (error) {
    let msg
    if (error.response?.data !== undefined && error.response?.data === 'Email is already in use') {
      msg = error.response?.data
    } else {
      msg = 'An error occurred while trying to update user'
    }
    await showNotification({
      type: 'error',
      message: msg,
    })
  } finally {
    decrementLoading(store)
  }
}

export const updateUserInformation = async (
  store,
  { memberId, newUserInformation, clickedAddMember, programId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await memberService.updateUserInformation(memberId, newUserInformation, clickedAddMember, programId)
    await setMemberToEdit(store, memberId)
    await showNotification({
      type: 'success',
      message: 'User successfully updated',
    })
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        userInformation: newUserInformation,
        currentTab: 'user-information',
      },
    }))
  } catch (error) {
    let msg
    if (error.response.data !== undefined && error.response.data.indexOf('Email is already in use.') >= 0) {
      msg = error.response.data
    } else {
      msg = 'An error occurred while trying to update user information'
    }
    await showNotification({
      type: 'error',
      message: msg,
    })
  } finally {
    decrementLoading(store)
  }
}

export const clearMembershipToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, membershipToEdit: undefined },
  }))
}

export const getSavedCreditCards = async (
  store,
  { memberId, subscriptionId },
) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, isLoadingSavedCreditCard: true },
  }))

  try {
    const cards = await memberService.getSavedCards(memberId, subscriptionId)

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        savedCreditCards: cards,
        isLoadingSavedCreditCard: false,
      },
    }))
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isLoadingReceipts: false,
      },
    }))
  }
}
export const getSavedCreditCardsFromProgram = async (
  store,
  { memberId, programId, courseId },
) => {
  const showNotification = store.state.Notification.actions.showNotification
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, isLoadingSavedCreditCard: true },
  }))

  try {
    const cards = await memberService.getSavedCardsFromProgram(memberId, programId, courseId)

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        savedCreditCards: cards,
        isLoadingSavedCreditCard: false,
      },
    }))
    return cards
  } catch (error) {

    showNotification({
      type: 'error',
      message:
        'Affiliate for User and Program do not match, please contact support. ',
    })
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isLoadingReceipts: false,
        isLoadingSavedCreditCard: false,
        upgradeInfo: undefined,
        purchaseMembership: {
          ...prevState.MemberDetails.purchaseMembership, billingPlanSummary: undefined,
          isLoadingInvoice: false, targetBillingPlanId: undefined
        }, showCheckoutInformation: false
      },
    }))
    return error
  }
}
export const getReceipts = async (
  store,
  { pagination, memberId, subscriptionId },
) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      isLoadingReceipts: true,
    },
  }))

  try {
    const userReceipts = await memberService.getReceipts(
      memberId,
      subscriptionId,
      pagination,
    )

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        receipts: userReceipts,
        isLoadingReceipts: false,
      },
    }))
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isLoadingReceipts: false,
      },
    }))
  }
}

export const setSelectedCreditCardId = (store, selectedCreditCardId) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      selectedCreditCardId: selectedCreditCardId,
    },
  }))
}

export const addNewCreditCard = async (
  store,
  { card, memberId, subscriptionId, isUpdatePayment, isPassDiscontinuedUpdatePayment, lastInvoiceId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  let creditCardId = 0;
  await memberService.addNewCreditCard(
    card,
    memberId,
    subscriptionId
  ).then((data) => {
    creditCardId = data && data.id
    if (isUpdatePayment && data && data.id > 0) {
      updateMembershipPaymentMethod(store, {
        userId: +memberId,
        membershipSubscriptionId: subscriptionId,
        creditCardId: data.id,
        isUpdateAllCreditCardPayment: isUpdatePayment,
      })
      if (isPassDiscontinuedUpdatePayment) {
        payLastFailedInvoiceMethod(store, {
          userId: +memberId,
          membershipSubscriptionId: subscriptionId,
          creditCardId: data.id,
          lastInvoiceId: lastInvoiceId
        })
      }
    }
  })
    .finally(() => {
      getSavedCreditCards(store, { memberId, subscriptionId })
      decrementLoading(store)
    })
  return creditCardId
}

export const addNewCreditCardForPurchase = async (
  store,
  { card, memberId, programId, courseId },
) => {
  const { id } = await memberService.addNewCreditCardForPurchase(
    card,
    memberId,
    programId,
    courseId,
  )
  await getSavedCreditCardsFromProgram(store, { memberId, programId })
  return id
}

export const deleteCreditCard = async (
  store,
  { creditCardId, memberId, subscriptionId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)

  try {
    await memberService.deleteCreditCard(creditCardId, memberId, subscriptionId)
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        creditCardDeleteError: error.response.data,
      },
    }))
  } finally {
    decrementLoading(store)
  }

  await getSavedCreditCards(store, { memberId, subscriptionId })
}

export const deleteCreditCardForPurchase = async (
  store,
  { creditCardId, memberId, programId, courseId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)

  try {
    await memberService.deleteCreditCardForPurchase(creditCardId, memberId, programId, courseId)
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        creditCardDeleteError: error.response.data,
      },
    }))
  } finally {
    decrementLoading(store)
  }

  await getSavedCreditCardsFromProgram(store, { memberId, programId })
}

export const clearCreditCardDeleteError = async store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      creditCardDeleteError: undefined,
    },
  }))
}

export const refundReceipt = async (
  store,
  { subscriptionId, receiptId, memberId, pagination },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)

  try {
    await memberService.refund(receiptId, subscriptionId)
    getReceipts(store, { subscriptionId, memberId, pagination })
  } finally {
    decrementLoading(store)
  }
}

export const cancelMembershipSubscription = (
  store,
  { memberId, subscriptionId, cancellationType, reloadMembership },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  return memberService
    .cancelMembershipSubscription(subscriptionId, cancellationType)
    .then(() =>
      reloadMembership
        ? setMembershipToEdit(store, { memberId, subscriptionId })
        : clearMembershipToEdit(store),
    )
    .finally(() => {
      const programId = state?.MemberDetails?.membershipToEdit?.programId;
      if (Number(programId) > 0) {
        // Refresh the User information
        getUserInformation(store, { memberId, clickedAddMember: false, programId });
      }
      decrementLoading(store)
    })
}

export const updateMembershipPaymentMethod = async (
  store,
  updateMembershipPaymentMethodData,
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const { userId, membershipSubscriptionId } = updateMembershipPaymentMethodData

  incrementLoading(store)

  try {
    await memberService.updatePaymentMethod(updateMembershipPaymentMethodData)
    await setMembershipToEdit(store, {
      memberId: userId,
      subscriptionId: membershipSubscriptionId,
    })
    decrementLoading(store)
  } catch (error) {
    decrementLoading(store)
  }
}

export const payLastFailedInvoiceMethod = async (
  store,
  payLastFailedInvoiceData,
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const { userId, membershipSubscriptionId } = payLastFailedInvoiceData

  incrementLoading(store)

  try {
    const response = await memberService.payLastFailedInvoice(payLastFailedInvoiceData)
    await setMembershipToEdit(store, {
      memberId: userId,
      subscriptionId: membershipSubscriptionId,
    })

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        lastInvoiceErrorMessage: response && response.errorMessage != "" ? response.errorMessage : null
      },
    }))

    decrementLoading(store)
  } catch (error) {
    decrementLoading(store)
  }
}

export const undoCancellationSubscription = (
  store,
  { memberId, subscriptionId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  return memberService
    .undoCancellationSubscription(memberId, subscriptionId)
    .then(() => setMembershipToEdit(store, { memberId, subscriptionId }))
    .finally(() => decrementLoading(store))
}

export const setBookingToEdit = (store, { memberId, id, isPast = false }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  return memberService
    .getBooking(memberId, id)
    .then(bookingToEdit =>
      store.setState(prevState => ({
        ...prevState,
        MemberDetails: {
          ...prevState.MemberDetails,
          bookingToEdit: { ...bookingToEdit.reservation, isPast },
        },
      })),
    )
    .catch(
      err =>
        err.response?.status === 403 &&
        showNotification({
          type: 'error',
          message:
            'Unauthorized to view this booking due to lack of course permissions',
        }),
    )
    .finally(() => decrementLoading(store))
}

export const clearBookingToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, bookingToEdit: undefined },
  }))
}

export const chargeNoShow = async (
  store,
  noShowChargeModel
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification
  let type, message
  incrementLoading(store)
  try {
    var response = await memberService.chargeNoShow(noShowChargeModel)
    if (response.status && response.status == 1) {
      type = "success"
      message = "No show charged successfully"
    } else {
      if (response.error !== undefined) {
        message = response.error
        type = "error"
      } else {
        type = "error"
        message = 'An error occurred while No Show charge'
      }
    }
    await showNotification({
      type: type,
      message: message,
    })
    setTimeout(async () => {
      setBookingToEdit(store, { memberId: noShowChargeModel.noShowChargePayment.userId, id: noShowChargeModel.noShowChargePayment.bookingId, isPast: noShowChargeModel.noShowChargePayment.isPast })
    }, 1000);
  } catch (error) {
    type = "error"
    if (error.response !== undefined && error.response.data !== undefined) {
      message = error.response.data
    } else {
      message = 'An error occurred while No Show charge'
    }
    await showNotification({
      type: 'error',
      message: message,
    })
  }
  finally {
    decrementLoading(store)
  }
}

export const cancelBooking = async (
  store,
  { memberId, bookingId, sgCourseId, programId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  const showNotification = state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await memberService.cancelBooking({
      reservationId: bookingId,
      userId: memberId,
      sgCourseId,
      programId
    })
    clearBookingToEdit(store)
  }
  catch (error) {
    let msg = error?.response ? error.response?.data?.split(".")[1].split(":")[2].replaceAll('"', "") : 'The cancel operation failed with an unexpected error!'
    msg = msg.replaceAll("}", "")
    showNotification({
      type: 'error',
      message: msg
    })
  }
  finally {
    decrementLoading(store)
  }
}

export const cancelAndRefundBooking = async (
  store,
  { memberId, bookingId, sgCourseId, programId, isDeposit, isBookingFee, isAdvanceReservationFee },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await memberService.cancelAndRefundBooking({
      reservationId: bookingId,
      userId: memberId,
      sgCourseId,
      programId,
      isDeposit,
      isBookingFee,
      isAdvanceReservationFee
    })
    decrementLoading(store)
    return true
  } catch (error) {
    let msg = error.response ? error.response?.data?.split(".")[1].split(":")[2].replaceAll('"', "") : 'The refund operation failed with an unexpected error!'
    msg = msg.replaceAll("}", "")

    showNotification({
      type: 'error',
      message: msg,
    })
    decrementLoading(store)
    return false
  }
}

export const suspendMembership = async (
  store,
  { memberId, subscriptionId, billingCycles },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  memberService
    .suspendMembership(memberId, subscriptionId, billingCycles)
    .then(suspendedMembershipInfo =>
      store.setState(prevState => ({
        ...prevState,
        MemberDetails: {
          ...prevState.MemberDetails,
          suspendedMembershipInfo,
        },
      })),
    )
    .then(() => setMembershipToEdit(store, { memberId, subscriptionId }))
    .finally(() => decrementLoading(store))
}

export const revokeSuspensionMembership = async (
  store,
  { memberId, subscriptionId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await memberService.revokeSuspensionMembership(memberId, subscriptionId)

    await setMembershipToEdit(store, { memberId, subscriptionId })

    setMembershipToEdit(store, { memberId, subscriptionId })
    decrementLoading(store)
  } catch (error) {
    showNotification({
      type: 'error',
      message:
        'We were unable to revoke this suspension, contact support for more information',
    })
    decrementLoading(store)
  }
}

export const clearSuspendedMembershipInfo = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      suspendedMembershipInfo: undefined,
    },
  }))
}
export const getTermsAndConditions = async (store, membershipId) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      isLoadingTermsAndConditions: true,
    },
  }))

  try {
    const res = await memberService.getTermsAndConditions(membershipId)

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isLoadingTermsAndConditions: false,
        termsAndConditions: res,
      },
    }))

    return res
  } catch (err) {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isLoadingTermsAndConditions: false,
      },
    }))

    return err
  }
}

export const getBookings = async (store, { memberId,
  activeBookingsPageSize,
  activeBookingsPageNumber,
  pastBookingsPageSize,
  pastBookingsPageNumber }) => {

  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)

  try {
    const upcomingResponse = await bookingService.getBookings({
      userId: memberId,
      status: 'upcoming',
      sort_by: 'tee_off_at',
      order: 'asc',
      page: activeBookingsPageNumber,
      per_page: activeBookingsPageSize
    })
    const pastResponse = await bookingService.getBookings({
      userId: memberId,
      status: 'past',
      sort_by: 'tee_off_at',
      order: 'desc',
      page: pastBookingsPageNumber,
      per_page: pastBookingsPageSize,
    })

    const activeBookings = upcomingResponse.reservations

    const pastBookings = pastResponse.reservations

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        bookings: {
          ...prevState.MemberDetails.bookings,
          activeBookings,
          pastBookings,
          activeBookingsTotalItems: upcomingResponse.totalCount ? upcomingResponse.totalCount : 0,
          pastBookingsTotalItems: pastResponse.totalCount ? pastResponse.totalCount : 0,
        },
      },
    }))
  } finally {
    decrementLoading(store)
  }
}
export const getEmployees = async (store, { programId, courseId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const employees = await employeeService.getActiveEmployeesAsync(programId, courseId);
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        employees,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const selectEmployee = async (store, employeeId) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      selectedEmployeeId: employeeId,
    },
  }))
}



export const updateCustomerId = async (
  store,
  { memberId, subscriptionId, customerNewId, eZCustomerNewId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await memberService.updateCustomerId(memberId, subscriptionId, { customerId: customerNewId, isEZProvider: (eZCustomerNewId != undefined && eZCustomerNewId != null) ? true : false, eZEmailCustomerId: eZCustomerNewId })
    await setMembershipToEdit(store, { memberId, subscriptionId })
    decrementLoading(store)
    await showNotification({
      type: 'success',
      message: 'updated successfully!',
    })
  } catch (error) {
    decrementLoading(store)
    await showNotification({
      type: 'error',
      message: error.response.data,
    })
  }
}


export const updateEmployee = async (
  store,
  { subscriptionId, employeeId, memberId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await employeeService.updateEmployee(memberId, subscriptionId, employeeId)
    await setMembershipToEdit(store, { memberId, subscriptionId })
    decrementLoading(store)
    await showNotification({
      type: 'success',
      message: 'Employee updated successfully!',
    })
  } catch {
    decrementLoading(store)
    await showNotification({
      type: 'error',
      message: 'Employee update failed!',
    })
  }
}

export const checkMembershipAndBillingPlanForPurchase = async (store, { memberId, membershipId, billingPlanId, scheduleId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)

  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      purchaseValidationStatus: PurchaseValidationStatus.IN_PROGRESS,
    },
  }))

  try {
    const { data } = await memberService.validateMembershipForPurchase(memberId, membershipId, billingPlanId, scheduleId)
    decrementLoading(store)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        upgradeInfo: data,
        purchaseValidationStatus: data ? PurchaseValidationStatus.UPGRADE : PurchaseValidationStatus.NEW_PURCHASE,
        showCheckoutInformation: data ? true : false
      },
    }))
    return data
  } catch (error) {
    decrementLoading(store)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        purchaseValidationStatus: error?.response?.data === "invalid-migration"
          ? PurchaseValidationStatus.INVALID_MIGRATION
          : PurchaseValidationStatus.SUBSCRIPTION_EXIST,
      },
    }))
    await showNotification({
      type: 'error',
      message: error?.response?.data === "invalid-migration"
        ? PurchaseValidationStatus.INVALID_MIGRATION.toLowerCase().replace('_', ' ')
        : PurchaseValidationStatus.SUBSCRIPTION_EXIST.toLowerCase().replace('_', ' '),
    })
  }
}

export const getBillingPlanSummary = async (
  store,
  { memberId, billingPlanId, sourceSubscriptionId, scheduleId }
) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, purchaseMembership: { ...prevState.MemberDetails.purchaseMembership, isLoadingInvoice: true } },
  }))
  incrementLoading(store)
  var timeoutCount = 1;
  try {
    const invoice = await memberService.getInitialInvoice(memberId, billingPlanId, sourceSubscriptionId, scheduleId)

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, purchaseMembership: { ...prevState.MemberDetails.purchaseMembership, billingPlanSummary: invoice, isLoadingInvoice: false, targetBillingPlanId: billingPlanId, targetScheduleId: scheduleId }, showCheckoutInformation: true },
    }))
    timeoutCount = 1000;
    return invoice
  }
  catch (error) {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, purchaseMembership: { ...prevState.MemberDetails.purchaseMembership, isLoadingInvoice: false }, showCheckoutInformation: false },
    }))
    await showNotification({
      type: 'error',
      message: 'Initial Invoice failed!',
    })
  }
  finally {
    setTimeout(() => {
      decrementLoading(store)
    }, timeoutCount)
  }

}
export const purchaseMembership = async (
  store,
  { memberId, membershipId, billingPlanId, selectedCreditCardId, selectedEmployeeId, scheduleId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      purchaseState: PurchaseStatusEnum.IN_PROGRESS,
    },
  }))
  incrementLoading(store)
  try {
    const purchaseConfirmationData = await memberService.purchaseMembership(
      memberId,
      membershipId,
      billingPlanId,
      selectedCreditCardId,
      selectedEmployeeId,
      scheduleId
    )

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        purchaseState: PurchaseStatusEnum.PURCHASE_SUCCESS,
        purchaseConfirmationData,
      },
      Member: {
        ...prevState.Member,
        email: '',
        currentTab: 'view'
      }
    }))
    decrementLoading(store)
    return !!purchaseConfirmationData
  } catch (error) {
    decrementLoading(store)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        purchaseState: PurchaseStatusEnum.PURCHASE_FAILED,
        paymentError: error.response.data
      },
      Member: {
        ...prevState.Member,
      }
    }))
  }
  finally {
    clearPurchaseMembershipState(store)
  }
}


export const upgradeMemberMembership = async (store, { memberId, membershipSourceId, targetMembershipId, targetBillingPlanId }) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    await memberService.upgradeMembership(memberId, membershipSourceId, targetMembershipId, targetBillingPlanId)
    decrementLoading(store)
    showNotification({
      type: 'success',
      message: 'Membership upgraded successfully!',
    })
  } catch (error) {
    decrementLoading(store)
    //console.log(error)
    showNotification({
      type: 'error',
      message: `Membership upgrading failed with error: ${error.response.data}`,
    })
  }
  finally {
    clearMembershipTab(store)
  }
}

export const upgradeMembershipFromPurchase = async (store, { memberId, membershipSourceId, targetMembershipId, targetBillingPlanId, selectedCreditCardId }) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      purchaseState: PurchaseStatusEnum.IN_PROGRESS,
    },
  }))

  incrementLoading(store)
  try {
    const creditCardId = selectedCreditCardId
    const purchaseConfirmationData = await memberService.upgradeMembership(memberId, membershipSourceId, targetMembershipId, targetBillingPlanId, creditCardId)
    decrementLoading(store)
    showNotification({
      type: 'success',
      message: 'Membership upgraded successfully!',
    })
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        purchaseState: PurchaseStatusEnum.PURCHASE_SUCCESS,
        purchaseConfirmationData,
      },
    }))
    return !!purchaseConfirmationData
  } catch (error) {
    decrementLoading(store)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        purchaseState: PurchaseStatusEnum.PURCHASE_FAILED,
        paymentError: error.response.data.detail
      },
    }))
    showNotification({
      type: 'error',
      message: `Membership upgrading failed with error: ${error.response.data}`,
    })
  }
  finally {
    clearUpgradeMembershipState(store)
  }
}

export const getTargetMembershipAndBillingPlan = async (store, membershipId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const isOneTimePass = state.MemberDetails.membershipToEdit.frequencyId == 0 ? true : false;
    const targetMembershipBillingPlan = await memberService.getTargetMembershipAndBillingPlan(membershipId, isOneTimePass)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        targetMembershipBillingPlan,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getUpgradeMembershipInvoice = async (
  store,
  { memberId, sourceSubscriptionId, billingPlanId, scheduleId }
) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, upgradeMembership: { ...prevState.MemberDetails.upgradeMembership, isLoadingInvoice: true } },
  }))
  incrementLoading(store)
  try {
    const invoice = await memberService.getUpgradeInvoice(memberId, sourceSubscriptionId, billingPlanId, scheduleId)

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, upgradeMembership: { ...prevState.MemberDetails.upgradeMembership, invoice, isLoadingInvoice: false, targetBillingPlanId: billingPlanId }, showCheckoutInformation: true },
    }))
  }
  catch {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, upgradeMembership: { ...prevState.MemberDetails.upgradeMembership, isLoadingInvoice: false }, showCheckoutInformation: false },
    }))
    await showNotification({
      type: 'error',
      message: 'Upgrade Invoice failed!',
    })
  }
  finally {
    decrementLoading(store)
  }
}


export const getUpgradeTargetMembership = async (
  store,
  membershipId
) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, upgradeMembership: { ...prevState.MemberDetails.upgradeMembership, isLoadingTargetMembership: true } },
  }))
  incrementLoading(store)
  try {
    const targetMembership = await memberService.getTargetMembershipById(membershipId)

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, upgradeMembership: { ...prevState.MemberDetails.upgradeMembership, targetMembership, isLoadingTargetMembership: false } },
    }))
  }
  catch {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, upgradeMembership: { ...prevState.MemberDetails.upgradeMembership, isLoadingTargetMembership: false }, showCheckoutInformation: false },
    }))
    await showNotification({
      type: 'error',
      message: 'Target Membership not found!',
    })
  }
  finally {
    decrementLoading(store)
  }
}

export const clearCheckoutMembership = store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, upgradeMembership: { ...prevState.MemberDetails.upgradeMembership, invoice: undefined, isLoadingInvoice: false, isLoadingTargetMembership: false, targetMembership: undefined, targetBillingPlanId: undefined }, showCheckoutInformation: false },
  }))
}

export const getReceiptPdf = async (
  store,
  { invoiceId, memberId, memberEmail, memberName }
) => {
  const showNotification = store.state.Notification.actions.showNotification
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, isDownloadReceipt: true }
  }))

  try {
    const { pdf } = await memberService.getReceiptPdf(invoiceId, memberEmail, memberName)
    const binaryString = window.atob(pdf);
    let bytes = new Uint8Array(binaryString.length)

    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([bytes], { type: "application/pdf" });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = `Invoice-${invoiceId}.pdf`;
    link.click();
  }
  catch (error) {
    await showNotification({
      type: 'error',
      message: 'Download invoice failed',
    })
  }
  finally {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: { ...prevState.MemberDetails, isDownloadReceipt: false }
    }))
  }
}

export const getAvailablePrograms = async store => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const defaultSelect = [{ name: "Select", "id": '' }]
  incrementLoading(store)
  try {
    const list = await programService.getProgramsForUser()
    const programList = [...defaultSelect, ...list]
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        availablePrograms: programList
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getAvailableBillingPlans = async (store, programId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const list = await reportsService.getBillingPlansByProgram(programId ?? '')
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        availableBillingPlans: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getMembershipsByProgram = async (store, programId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    const list = await memberService.getMembershipsByProgram(programId ?? '')
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        availableMemberships: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const setBillingPlanId = (store, billingplanid) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, selectedBillingPlanid: billingplanid },
  }))
}
export const setBillingPlanSummary = (store, billingPlanSummary) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, purchaseMembership: { billingPlanSummary: billingPlanSummary } },
  }))
}

export const setIsRedirectToPurchaseMembership = (store, isRedirectToPurchaseMembership) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, isRedirectToPurchaseMembership: isRedirectToPurchaseMembership },
  }))
}
export const setIsPurchase = (store, isPurchase) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, isPurchase: isPurchase },
  }))
}

export const setPurchaseMembershipfilters = (store, purchaseMembershipfilters) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, purchaseMembershipfilters: purchaseMembershipfilters },
  }))
}

export const setMembershipId = (store, membershipId) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, selectedMembershipId: membershipId },
  }))
}
export const setIsMember = (store, isMember) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, isMember: isMember },
  }))
}
export const setCurrentStep = (store, currentStep) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: { ...prevState.MemberDetails, currentStep },
  }))
}
export const initializePayment = async store => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      purchaseState: PurchaseStatusEnum.READY,
      selectedCreditCardId: undefined,
      isAddCardVisible: false,
      purchaseConfirmationData: undefined,
      employees: [],
      selectedEmployeeId: undefined,
      isLoadingEmployees: false,
    },
  }))
}


export const getMembershipsHistory = async (store, { memberId, subscriptionId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        membershipHistoryList: [],
        // currentTab: 'memberships-history',
      },
    }))

    const items = await memberService.getMembershipsHistory(memberId, subscriptionId)
    //const isCustomerDataMissing = await memberService.getCustomerDataMissing(memberId)

    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        membershipHistoryList: items,
        //currentTab: 'memberships-history',
      },
    }))
    decrementLoading(store)
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const getCustomerProgramDetail = async (store, { programId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const customerProgramDetail = await memberService.getProgramConfigForCustomerProfile(programId)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        customerProgramDetail,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}


export const checkIsNationalNetworkMember = async (store, memberId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    const isNationalNetworkMember = await memberService.checkIsNationalNetworkMember(memberId)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isNationalNetworkMember: isNationalNetworkMember,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const modifyBooking = async (store, { programId, reservationId, newQty, userId, previousQty, isDeposit,
  isBookingFee,
  isAdvanceReservationFee, creditCardId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    await memberService.modifyBooking(programId, reservationId, newQty, userId, previousQty, isDeposit,
      isBookingFee,
      isAdvanceReservationFee, creditCardId)
    return true
  }
  catch (error) {
    return false
  }
  finally {
    decrementLoading(store)
  }
}

export const getCheckoutData = async (store, { programId, reservationId, newQty, userId, isDeposit, isBookingFee, isAdvanceReservationFee }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    if (isDeposit != undefined) {
      return await memberService.getSelectedRefundCheckoutData(programId, reservationId, newQty, userId, isDeposit, isBookingFee, isAdvanceReservationFee)
    }
    else {
      const checkoutData = await memberService.getCheckoutData(programId, reservationId, newQty, userId)
      store.setState(prevState => ({
        ...prevState,
        CheckoutData: {
          ...prevState.CheckoutData,
          checkoutData: checkoutData
        }
      }))
      return checkoutData
    }
  }
  catch (error) {
    return false
  } finally {
    decrementLoading(store)
  }
}



export const checkUserMembershipDetails = async (store, { memberUserId, programId }) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      isUserAlreadyHaveMembershipDetails: false
    },
  }))

  try {
    const isUserAlreadyHaveMembershipDetails = await memberService.checkUserMembershipDetails(memberUserId, programId)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isUserAlreadyHaveMembershipDetails
      },
    }))
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isUserAlreadyHaveMembershipDetails: false,
      },
    }))
  }
}

export const getActiveMemberships = async (store, { memberUserId, programId }) => {
  store.setState(prevState => ({
    ...prevState,
    MemberDetails: {
      ...prevState.MemberDetails,
      isUserActiveMembership: false
    },
  }))

  try {
    const isUserActiveMembership = await memberService.getActiveMemberships(memberUserId, programId)
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isUserActiveMembership
      },
    }))
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      MemberDetails: {
        ...prevState.MemberDetails,
        isUserActiveMembership: false,
      },
    }))
  }
}
